import React, { useState, Fragment, useEffect } from "react";
import NavLogo from "../images/Wordmark-Logo-Light-Gray.svg";
import MainLogo from "../images/Wordmark-Logo-Blue.svg";
import Favicon from "../images/Favicon.png";
import { Helmet } from "react-helmet";

const SelectSupplierPage = (props) => {
  //
  //
  ///
  /*Start FaunaDB*/
  /*Imports*/
  //
  //
  const faunadb = require("faunadb");
  const q = faunadb.query;

  const client = new faunadb.Client({
    secret: "fnAFYC386qAAQC4nGVmj_Ct4UQp-My63biKkpviJ",
    //secret: "fnAEzBL0dOACT6zMMFftm4TpL9YnKvLh4_xzIRrZ",/Main-opengate secret/
  });
  //
  //
  //
  //
  const [userCredentials, setuserCredentials] = useState("");
  const [activeUser, setactiveUser] = useState("");
  const [isUserLoggedIn, setisUserLoggedIn] = useState(false);
  const [pickSupplier, setpickSupplier] = useState("");
  //
  //
  // Check Access
  //
  //
  useEffect(() => {
    const userAccess = window.localStorage.getItem("USER_CREDENTIALS");
    const activeUserData = window.localStorage.getItem("ACTIVE_USER");
    if (userAccess !== "null") {
      const foundUser = JSON.parse(userAccess);
      const loggedInUserData = JSON.parse(activeUserData);
      setactiveUser(loggedInUserData);
      setuserCredentials(foundUser);
    } else setisUserLoggedIn(false);
    // window.location.href = `/`;
  }, [userCredentials.secret]);
  //
  //

  //
  //
  var activeUserReference = null;

  switch (typeof userCredentials.secret) {
    case "string":
      var activeUserReference = userCredentials.instance["@ref"].id;
      break;
    default:
      var activeUserReference = null;
      break;
  }

  //
  //
  //
  //
  //
  //
  //
  //
  //
  //
  /* const getUser = async () => {
    {
      const loggedInUser = await client.query(
        q.Map(
          q.Paginate(
            q.Match(q.Index("users_by_reference"), activeUserReference)
          ),
          q.Lambda((x) => q.Get(x))
        )
      );
      setactiveUser(loggedInUser.data);
    }
  };*/

  //
  //
  //
  //
  //
  //
  //
  //
  //
  var activeUserVendor = null;
  var activeUserRole = null;
  var activeUserEmail = null;
  var activeUserSuppliers = null;
  //
  switch (Object.keys(activeUser).length === 0) {
    case false:
      activeUserVendor = activeUser.map((post) => {
        return post.data.vendor.openGateVendorID;
      });
      activeUserRole = activeUser.map((post) => {
        return post.data.role;
      });
      activeUserEmail = activeUser.map((post) => {
        return post.data.email;
      });
      activeUserSuppliers = activeUser.map((post) => {
        return post.data.suppliers;
      });
      break;
    default:
      activeUserVendor = null;
      break;
  }

  if (Object.keys(activeUser).length === 0) {
    return (
      <>
        <Helmet>
          <meta charSet="utf-8" />
          <title>OpenGate | Third Party Verification</title>

          <meta
            name="description"
            content="Your new moden third party verification option for all your needs."
          />
          <meta property="og:locale" content="en_US"></meta>
          <meta property="og:type" content="website"></meta>
          <meta
            property="og:title"
            content=" OpenGate | Third Party Verification"
          ></meta>
          <meta
            property="og:description"
            content="Your new moden third party verification option for all your needs."
          ></meta>
          <meta
            property="og:site_name"
            content=" OpenGate | Third Party Verification"
          ></meta>
          <meta name="twitter:card" content="summary_large_image"></meta>
          <meta
            name="twitter:description"
            content="Your new moden third party verification option for all your needs."
          ></meta>
          <meta
            name="twitter:title"
            content="OpenGate | Third Party Verification"
          ></meta>
          <link rel="icon" href={Favicon} />
        </Helmet>

        <div class="animate-slow h-full bg-gray-200 md:h-screen">
          <div class="min-h-full px-4 py-16 sm:px-6 sm:py-24 md:grid md:place-items-center lg:px-8">
            <main class="bg-white mx-auto flex max-w-3xl rounded-xl flex-grow flex-col justify-center px-9 pt-9">
              <div class="flex flex-shrink-0 justify-center">
                <a href="/" class="inline-flex">
                  <img class="w-full mx-auto" src={MainLogo} alt="" />
                </a>
              </div>
              <div class="pt-10 pb-10">
                <div class="text-center">
                  <h1 class="mt-2 text-4xl font-bold tracking-tight text-gray-900 sm:text-5xl">
                    Please log in
                  </h1>
                  <p class="mt-2 text-base text-gray-500">
                    You do not have access to view this page.
                  </p>
                  <div class="mt-6">
                    <a
                      href="/"
                      class="text-base font-medium text-blue-700 hover:text-blue-500"
                    >
                      Return home
                      <span aria-hidden="true"> &rarr;</span>
                    </a>
                  </div>
                </div>
              </div>
            </main>
            <footer class="mx-auto w-full max-w-7xl flex-shrink-0 px-4 sm:px-6 lg:px-8">
              <nav class="flex justify-center space-x-4">
                <a
                  href="#"
                  class="text-sm font-medium text-gray-500 hover:text-gray-600"
                >
                  Contact Support
                </a>
                <span
                  class="inline-block border-l border-gray-300"
                  aria-hidden="true"
                ></span>
                <a
                  href="#"
                  class="text-sm font-medium text-gray-500 hover:text-gray-600"
                >
                  Status
                </a>
              </nav>
            </footer>
          </div>
        </div>
      </>
    );
  } else
    return (
      <>
        <Helmet>
          <meta charSet="utf-8" />
          <title>OpenGate | Third Party Verification</title>

          <meta
            name="description"
            content="Your new moden third party verification option for all your needs."
          />
          <meta property="og:locale" content="en_US"></meta>
          <meta property="og:type" content="website"></meta>
          <meta
            property="og:title"
            content=" OpenGate | Third Party Verification"
          ></meta>
          <meta
            property="og:description"
            content="Your new moden third party verification option for all your needs."
          ></meta>
          <meta
            property="og:site_name"
            content=" OpenGate | Third Party Verification"
          ></meta>
          <meta name="twitter:card" content="summary_large_image"></meta>
          <meta
            name="twitter:description"
            content="Your new moden third party verification option for all your needs."
          ></meta>
          <meta
            name="twitter:title"
            content="OpenGate | Third Party Verification"
          ></meta>
          <link rel="icon" href={Favicon} />
        </Helmet>

        <div class="">
          {activeUserSuppliers[0].length === 1 ? (
            <div class="z-30 lg:block hidden">
              {activeUserSuppliers[0].toString() === "Greenwave_Energy" ? (
                <>
                  {window.localStorage.setItem(
                    "USER_SUPPLIER",
                    JSON.stringify("fnAFYC5vuiAAQHKl0jbZM6ySKjTjhR80IwdpNLy0")
                  )}
                  {window.localStorage.setItem(
                    "ACTIVE_SUPPLIER",
                    JSON.stringify("Greenwave-Energy")
                  )}
                  <span class="hidden">
                    {" "}
                    {(window.location.href = `/app`)}{" "}
                  </span>
                </>
              ) : (
                <></>
              )}
              {activeUserSuppliers[0].toString() === "Callective" ? (
                <>
                  {window.localStorage.setItem(
                    "USER_SUPPLIER",
                    JSON.stringify("fnAFYC5pbEAAQoRZJRKoRzwaoIToIVr0UwhfwQrb")
                  )}
                  {window.localStorage.setItem(
                    "ACTIVE_SUPPLIER",
                    JSON.stringify("Callective-Energy")
                  )}
                  <span class="hidden">
                    {" "}
                    {(window.location.href = `/app`)}{" "}
                  </span>
                </>
              ) : (
                <></>
              )}
              {activeUserSuppliers[0].toString() === "Kratos_Duke" ? (
                <>
                  {window.localStorage.setItem(
                    "USER_SUPPLIER",
                    JSON.stringify("fnAFYC5_wxAAQEBIW4p4gsDl6ScyWmpLdyj3PPd_")
                  )}
                  {window.localStorage.setItem(
                    "ACTIVE_SUPPLIER",
                    JSON.stringify("Kratos-Duke")
                  )}
                  <span class="hidden">
                    {" "}
                    {(window.location.href = `/app`)}{" "}
                  </span>
                </>
              ) : (
                <></>
              )}
            </div>
          ) : (
            <div class="relative z-10" role="dialog" aria-modal="true">
              <div class="fixed inset-0 bg-gray-500 bg-opacity-25 transition-opacity"></div>

              <div class="fixed inset-0 z-10 overflow-y-auto p-4 sm:p-6 md:p-20">
                <div class="mx-auto max-w-xl transform divide-y divide-gray-100 overflow-hidden rounded-xl bg-white shadow-2xl ring-1 ring-black ring-opacity-5 transition-all">
                  <div>
                    <img
                      class="mx-auto h-30 w-3/4 pt-10"
                      src={MainLogo}
                      alt="Your Company"
                    ></img>
                    <h2 class="mb-6 mt-6 text-center text-2xl font-semibold tracking-tight text-gray-500">
                      Select A Supplier
                    </h2>
                  </div>
                  <div class="relative">
                    <svg
                      class="pointer-events-none absolute top-3.5 left-4 h-5 w-5 text-gray-400"
                      xmlns="http://www.w3.org/2000/svg"
                      viewBox="0 0 20 20"
                      fill="currentColor"
                      aria-hidden="true"
                    >
                      <path
                        fill-rule="evenodd"
                        d="M9 3.5a5.5 5.5 0 100 11 5.5 5.5 0 000-11zM2 9a7 7 0 1112.452 4.391l3.328 3.329a.75.75 0 11-1.06 1.06l-3.329-3.328A7 7 0 012 9z"
                        clip-rule="evenodd"
                      />
                    </svg>
                    <input
                      type="text"
                      class="h-12 w-full border-0 bg-transparent pl-11 pr-4 text-gray-800 placeholder-gray-400 focus:ring-0 sm:text-sm"
                      placeholder="Search..."
                      role="combobox"
                      aria-expanded="false"
                      aria-controls="options"
                    />
                  </div>

                  <ul
                    class="max-h-80 scroll-py-10 scroll-pb-2 space-y-4 overflow-y-auto p-4 pb-2"
                    id="options"
                    role="listbox"
                  >
                    <li>
                      <h2 class="text-xs font-semibold text-gray-900">
                        Assigned Suppliers
                      </h2>

                      {pickSupplier === "" ? (
                        <ul class="-mx-4 mt-2 text-sm text-gray-700">
                          {activeUserSuppliers[0].map((post) => {
                            return (
                              <>
                                <li
                                  class="rounded-lg mx-2 group flex cursor-default select-none items-center px-4 py-2 hover:bg-tpvRed"
                                  id="option-1"
                                  role="option"
                                  tabindex="-1"
                                >
                                  <button
                                    onClick={() => setpickSupplier(post)}
                                    class="inline-flex items-center"
                                  >
                                    {" "}
                                    <svg
                                      class="h-8 w-8 flex-none text-gray-400 group-hover:text-white"
                                      xmlns="http://www.w3.org/2000/svg"
                                      fill="none"
                                      viewBox="0 0 24 24"
                                      stroke-width="1.5"
                                      stroke="currentColor"
                                      aria-hidden="true"
                                    >
                                      <path
                                        stroke-linecap="round"
                                        stroke-linejoin="round"
                                        d="M2.25 12.75V12A2.25 2.25 0 014.5 9.75h15A2.25 2.25 0 0121.75 12v.75m-8.69-6.44l-2.12-2.12a1.5 1.5 0 00-1.061-.44H4.5A2.25 2.25 0 002.25 6v12a2.25 2.25 0 002.25 2.25h15A2.25 2.25 0 0021.75 18V9a2.25 2.25 0 00-2.25-2.25h-5.379a1.5 1.5 0 01-1.06-.44z"
                                      />
                                    </svg>
                                    <span class="text-lg ml-3 flex-auto truncate group-hover:text-white">
                                      {post}
                                    </span>
                                  </button>
                                </li>
                              </>
                            );
                          })}
                        </ul>
                      ) : (
                        <>
                          {pickSupplier === "Callective" ? (
                            <>
                              <div class="mt-4 text-lg ml-3 flex-auto truncate group-hover:text-white">
                                Loading Callective Energy....
                              </div>
                              {window.localStorage.setItem(
                                "USER_SUPPLIER",
                                JSON.stringify(
                                  "fnAFYC5pbEAAQoRZJRKoRzwaoIToIVr0UwhfwQrb"
                                )
                              )}
                              {window.localStorage.setItem(
                                "ACTIVE_SUPPLIER",
                                JSON.stringify("Callective-Energy")
                              )}
                              <span class="hidden">
                                {" "}
                                {(window.location.href = `/app`)}{" "}
                              </span>
                            </>
                          ) : (
                            <></>
                          )}
                          {pickSupplier === "Kratos_Ohio" ? (
                            <>
                              <div class="mt-4 text-lg ml-3 flex-auto truncate group-hover:text-white">
                                Loading Kratos Ohio....
                              </div>
                              {window.localStorage.setItem(
                                "USER_SUPPLIER",
                                JSON.stringify(
                                  "fnAFYC6WwiAAQI93MiffluUh1iR_1I2AoxFZXj-6"
                                )
                              )}
                              {window.localStorage.setItem(
                                "ACTIVE_SUPPLIER",
                                JSON.stringify("Kratos-Ohio")
                              )}
                              <span class="hidden">
                                {" "}
                                {(window.location.href = `/app`)}{" "}
                              </span>
                            </>
                          ) : (
                            <></>
                          )}
                          {pickSupplier === "Kratos_Michigan" ? (
                            <>
                              <div class="mt-4 text-lg ml-3 flex-auto truncate group-hover:text-white">
                                Loading Kratos Michigan....
                              </div>
                              {window.localStorage.setItem(
                                "USER_SUPPLIER",
                                JSON.stringify(
                                  "fnAFYC6M1yAAQJ8ocro91gzUxn4A6XkLOsgMbh01"
                                )
                              )}
                              {window.localStorage.setItem(
                                "ACTIVE_SUPPLIER",
                                JSON.stringify("Kratos-Michigan")
                              )}
                              <span class="hidden">
                                {" "}
                                {(window.location.href = `/app`)}{" "}
                              </span>
                            </>
                          ) : (
                            <></>
                          )}
                          {pickSupplier === "Greenwave_Energy" ? (
                            <>
                              <div class="mt-4 text-lg ml-3 flex-auto truncate group-hover:text-white">
                                Loading Greenwave Energy....
                              </div>
                              {window.localStorage.setItem(
                                "USER_SUPPLIER",
                                JSON.stringify(
                                  "fnAFYC5vuiAAQHKl0jbZM6ySKjTjhR80IwdpNLy0"
                                )
                              )}
                              {window.localStorage.setItem(
                                "ACTIVE_SUPPLIER",
                                JSON.stringify("Greenwave-Energy")
                              )}
                              <span class="hidden">
                                {" "}
                                {(window.location.href = `/app`)}{" "}
                              </span>
                            </>
                          ) : (
                            <></>
                          )}

                          {pickSupplier === "Kratos_Duke" ? (
                            <>
                              <div class="mt-4 text-lg ml-3 flex-auto truncate group-hover:text-white">
                                Loading Kratos Duke Energy....
                              </div>
                              {window.localStorage.setItem(
                                "USER_SUPPLIER",
                                JSON.stringify(
                                  "fnAFYC5_wxAAQEBIW4p4gsDl6ScyWmpLdyj3PPd_"
                                )
                              )}
                              {window.localStorage.setItem(
                                "ACTIVE_SUPPLIER",
                                JSON.stringify("Kratos-Duke")
                              )}
                              <span class="hidden">
                                {" "}
                                {(window.location.href = `/app`)}{" "}
                              </span>
                            </>
                          ) : (
                            <></>
                          )}
                        </>
                      )}
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          )}
        </div>
      </>
    );
};

export default SelectSupplierPage;

// {(window.location.href = `./app`)}//
